body {
    background-color: rgb(23, 23, 23);
    color: #e5e4e2;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;

}

a {
    text-decoration: none;
    color: #e5e4e2;
}